import React from "react";

import { Loading, Container, Text, Button } from '../../components'

import { PatientForm as StyledPatientForm } from './PatientForm.styles';

import { formatCpf, formatDate, formatPhone } from '../../utils/formatter';

const GENDER = {
	masculino: 'Masculino',
	feminino: 'Feminino'
};

function PatientForm({
	loading,
	isValid,
	values,
	variant,
	setFieldValue,
	onSubmitClick,
	onChange,
	handleNavigateHome,
	onChangeWithMask
}) {
	if (loading) {
		return <Loading />
	}
	return (
		<Container padded>
			<StyledPatientForm.HeaderContainer>
				<Text size="large" weight="semibold">Cadastro</Text>

				<Button variant="tertiary" onClick={handleNavigateHome}>
					<i className="doca-icon doca-icon--large doca-icon__xmark" />
				</Button>
			</StyledPatientForm.HeaderContainer>

			<Text marginBottom={16} variant="gray">Informe os dados necessários para concluir seu agendamento</Text>

			<form onSubmit={onSubmitClick}>
				<StyledPatientForm.InputField
					label="Nome Completo"
					placeholder="Sem abreviações"
					name="name"
					value={values?.name}
					onChange={onChange}
				/>

				<div className='doca-form__field doca-mb-6'>
					<label className="doca-form__label">
						CPF
					</label>

					<div className="doca-form-control__icon-container">
						<input
							disabled={values?.is_foreigner?.includes('on')}
							className="doca-form-control"
							name="cpf"
							placeholder="CPF"
							onChange={event => onChangeWithMask(event, formatCpf)}
							type="tel"
							value={values?.cpf}
						/>

						<div className="doca-checkbox__container checkbox--absolute-input">
							<label htmlFor="is-foreigner" className="doca-form__label doca-form__label--inline doca-mr-2">
								Estrangeiro
							</label>

							<input
								id="is-foreigner"
								className="doca-checkbox"
								type="checkbox"
								name="is_foreigner"
								onChange={onChange}
								checked={!!values.is_foreigner?.includes('on')}
							/>
						</div>
					</div>
				</div>

				<StyledPatientForm.InputField
					label="Data de nascimento"
					name="born"
					value={values?.born}
					type="tel"
					onChange={event => onChangeWithMask(event, formatDate)}
				/>

				<div className="doca-shortcut__container d-block doca-mb-6">
					<button type="button" className={`doca-shortcut w-100 ${values.gender === GENDER.masculino ? `doca-shortcut--${variant}` : ''}`} onClick={() => setFieldValue('gender', GENDER.masculino)}>
						<div>
							<i className="doca-icon doca-icon--large doca-icon__person" />

							<p className="doca-shortcut__title">
								Masculino
							</p>
						</div>
					</button>

					<button type="button" className={`doca-shortcut w-100 ${values.gender === GENDER.feminino ? `doca-shortcut--${variant}` : ''}`} onClick={() => setFieldValue('gender', GENDER.feminino)}>
						<div>
							<i className="doca-icon doca-icon--large doca-icon__person-dress" />

							<p className="doca-shortcut__title">
								Feminino
							</p>
						</div>
					</button>
				</div>

				<StyledPatientForm.InputField
					label="Celular"
					name="contact_cellphone"
					value={values?.contact_cellphone}
					type="tel"
					maxLength={15}
					onChange={event => onChangeWithMask(event, formatPhone)}
					placeholder="Digite com DDD"
				/>

				<StyledPatientForm.InputField
					label="E-mail"
					name="email"
					type="email"
					value={values?.email}
					onChange={onChange}
				/>

				<div className="doca-checkbox__container doca-mb-4">
					<input
						id="terms-of-use"
						className="doca-checkbox"
						type="checkbox"
						name="terms_of_use"
						onChange={onChange}
						checked={!!values.terms_of_use?.includes('on')}
					/>

					<label htmlFor="terms-of-use" className="doca-form__label doca-form__label--inline">
						Aceito os <a href="https://amigoapp.tech/clinic/termos-de-uso/" className="doca-link" target="_blank" rel="noreferrer">Termos de uso</a> e <a href="https://amigoapp.tech/clinic/politica-de-privacidade/" className="doca-link" target="_blank" rel="noreferrer">Privacidade</a>.
					</label>
				</div>

				<Button
					block
					size="large"
					disabled={!isValid}
					marginBottom={16}
					variant={variant}
				>
					Iniciar agendamento
				</Button>
			</form>
		</Container>
	);
}

export default PatientForm;
