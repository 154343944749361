import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from "formik";

import PatientForm from './PatientForm';

import { create } from '../../processes/patient'
import { useCompanyContext } from '../../contexts/company';
import { DateUtils, NavigationUtils, FormatterUtils } from '../../utils';

function PatientFormContainer() {
	const { form, settings, setInfo } = useCompanyContext();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const validate = values => {
		const errors = {};

		if (!values.name) {
			errors.name = 'O nome é um campo obrigatório.';
		}

		if (!values.cpf && !values.is_foreigner) {
			errors.cpf = "O cpf é um campo obrigatório.";
		}

		if (!values.gender) {
			errors.gender = 'O gênero é um campo obrigatório.'
		}

		if (!values.born) {
			errors.born = 'A data de nascimento é um campo obrigatório.'
		}

		if (!values.contact_cellphone) {
			errors.contact_cellphone = 'O telefone é um campo obrigatório.'
		}

		if (!values.email) {
			errors.email = 'O email é um campo obrigatório.'
		}

		if (!values.terms_of_use?.includes('on')) {
			errors.terms_of_use = 'É necessário aceitar os termos de uso.'
		}

		return errors;
	};

	const handleSubmitPress = useCallback(async values => {
		setLoading(true);

		const { data, error } = await create({
			...values,
			cpf: values.cpf && FormatterUtils.clearMask(values.cpf),
			born: DateUtils.formatDate(values.born, 'YYYY-MM-DD', 'DD/MM/YYYY'),
			contact_cellphone: FormatterUtils.clearMask(values.contact_cellphone),
			is_foreigner: !!values.is_foreigner?.includes('on'),
			terms_of_use: !!values.terms_of_use?.includes('on')
		});

		setLoading(false);

		if (error) {
			toast.error('Não foi possível criar o paciente.');
		} else {
			setInfo(info => ({
				...info,
				form: {
					...info.form,
					patient: {
						id: data.id,
						...values
					}
				}
			}));

			localStorage.setItem('patient', JSON.stringify(values))

			NavigationUtils.navigate(history, '/select-event-group');
		}

	}, [setInfo, setLoading, history]);

	const {
		handleSubmit,
		handleChange,
		isValid,
		setFieldValue,
		values
	} = useFormik({
		initialValues: {
			born: form?.patient?.born || '',
			name: form?.patient?.name,
			is_foreigner: form?.patient?.is_foreigner,
			cpf: form?.patient?.cpf ? FormatterUtils.formatCpf(form?.patient.cpf) : '',
			gender: form?.patient?.gender,
			contact_cellphone: form?.patient?.contact_cellphone ? FormatterUtils.formatPhone(form.patient.contact_cellphone) : '',
			email: form?.patient?.email,
			terms_of_use: form?.patient?.terms_of_use
		},
		validate,
		validateOnMount: true,
		onSubmit: handleSubmitPress
	});

	const onChangeWithMask = useCallback((event, mask) => {
		if (event.target.value) {
			event.target.value = mask(event.target.value);
		}

		return handleChange(event)
	}, [handleChange]);

	const handleNavigateHome = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values,
				patient: null
			}
		}));

		NavigationUtils.goBackHome(history);
	}, [history, setInfo]);

	return (
		<PatientForm
			values={values}
			loading={loading}
			onChange={handleChange}
			isValid={isValid}
			variant={settings.theme?.name}
			onSubmitClick={handleSubmit}
			setFieldValue={setFieldValue}
			onChangeWithMask={onChangeWithMask}
			handleNavigateHome={handleNavigateHome}
		/>
	);
}

export default PatientFormContainer;
